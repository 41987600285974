import React from 'react';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import LinkIcon from '@mui/icons-material/Link';
import PaymentIcon from '@mui/icons-material/Payment';
import CalculateIcon from '@mui/icons-material/Calculate';

import Page from '../../Components/Page';
import SEO from '../../Components/SEO';

import DiscordIcon from '../../Images/SVGIcons/Discord';

import logoImage from '../../Images/logo-192x192.png';

const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: 936,
        margin: 'auto',
        overflow: 'hidden'
    },
    contentWrapper: {
        margin: '40px 16px'
    },
    widgetBot: {
        width: '100%',
        height: 400
    },
    zkiliPreviewContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    zkiliPreview: {
        width: '100%',
        maxWidth: 540,
        margin: theme.spacing(2)
    },
    title: {
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    titleIcon: {
        marginRight: theme.spacing(1)
    }
}));

const Home = () => {
    const classes = useStyles();

    return (
        <Page title="Home">
            <SEO path="/" description="The Zkili discord bot dashboard" image={logoImage} />

            <Grid container spacing={2} justifyContent="space-around" alignItems="center">
                <Grid item xs={12} md={5}>
                    <a target="_blank" rel="noreferrer" href="https://discord.gg/b8DWFhRbRh">
                        <Paper className={classes.paper}>
                            <div className={classes.contentWrapper}>
                                <Typography variant="h5" color="secondary" align="center" className={classes.title}>
                                    <DiscordIcon color="secondary" className={classes.titleIcon} />
                                    Zkili discord relay
                                </Typography>
                                <Typography color="textSecondary" align="center">
                                    Get discord notifications for Titan losses, AT ship kills, high ISK value losses and
                                    more.
                                </Typography>
                            </div>
                        </Paper>
                    </a>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Link to={'/unique-kills'}>
                        <Paper className={classes.paper}>
                            <div className={classes.contentWrapper}>
                                <Typography variant="h5" color="secondary" align="center" className={classes.title}>
                                    <CalculateIcon color="secondary" className={classes.titleIcon} />
                                    Unique kills
                                </Typography>
                                <Typography color="textSecondary" align="center">
                                    Calculate how many actual total kills you have across all your alt character.
                                </Typography>
                            </div>
                        </Paper>
                    </Link>
                </Grid>
                {/*<Grid item xs={12} md={5}>*/}
                {/*    <Link to={'/pack-values'}>*/}
                {/*        <Paper className={classes.paper}>*/}
                {/*            <div className={classes.contentWrapper}>*/}
                {/*                <Typography variant="h5" color="secondary" align="center" className={classes.title}>*/}
                {/*                    <PaymentIcon color="secondary" className={classes.titleIcon} />*/}
                {/*                    Pack Value Calculator*/}
                {/*                </Typography>*/}
                {/*                <Typography color="textSecondary" align="center">*/}
                {/*                    Find which packs give the most value for your mone for SP, Omega time and ISK*/}
                {/*                    values.*/}
                {/*                </Typography>*/}
                {/*            </div>*/}
                {/*        </Paper>*/}
                {/*    </Link>*/}
                {/*</Grid>*/}
                <Grid item xs={12} md={5}>
                    <Link to={'/zkili-links'}>
                        <Paper className={classes.paper}>
                            <div className={classes.contentWrapper}>
                                <Typography variant="h5" color="secondary" align="center" className={classes.title}>
                                    <LinkIcon color="secondary" className={classes.titleIcon} />
                                    El'Miner links
                                </Typography>
                                <Typography color="textSecondary" align="center">
                                    Send fake Zkillboard links on Discord, Slack and Telegram which actually link to
                                    El'Miner.
                                </Typography>
                            </div>
                        </Paper>
                    </Link>
                </Grid>
                <Grid item xs={12} />

                <a href="/auth/eve-callback" alt="Hidden link for SEO purposes" style={{ display: 'none' }}>
                    Hidden link
                </a>
            </Grid>
        </Page>
    );
};

export default Home;
